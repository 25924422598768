// 自動生成用テスト

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "@components/layout"

import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/about-us.json";
import langJa from "@content/locales/ja/about-us.json";
// import IframeResizer from 'iframe-resizer-react';

// ベーシック認証milton1010
import { BasicAuthentication } from "@components/basicAuthentication.js";


const Test = ( props ) => {
  const data = useStaticQuery(graphql`
  query WpPostsTest {
    jaBlog: allWpArticle(
      filter: {articleTag: {nodes: {elemMatch: {slug: {eq: "ja"}}}}}
      sort: {fields: [date], order: DESC}
      ) {
      edges {
        node {
          slug
          title
          excerpt
          content
          date(formatString: "MMMM DD, YYYY")
          modified(formatString: "MMMM DD, YYYY")
          articleCategory {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
        next {
          slug
        }
        previous {
          slug
        }
      }
    }
    enBlog: allWpArticle(
      filter: {articleTag: {nodes: {elemMatch: {slug: {eq: "en"}}}}}
      sort: {fields: [date], order: DESC}
      ) {
      edges {
        node {
          slug
          title
          excerpt
          content
          date(formatString: "MMMM DD, YYYY")
          modified(formatString: "MMMM DD, YYYY")
          articleCategory {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
        next {
          slug
        }
        previous {
          slug
        }
      }
    }
    blogCategory :allWpArticleCategory(
      filter: {count: {ne: null}}
      sort: {order: DESC, fields: count}) {
      edges {
        node {
          name
          slug
          description
        }
      }
    }
    custompageData :allWpCustompage {
      edges {
        node {
          slug
          zerozeroCampaign {
            startdate
            enddate
            currencypair
          }
        }
      }
    }
    campaignData: allWpCampaign (sort: {fields: date, order: DESC}) {
      edges {
        node {
          slug
          campaignCategory {
            nodes {
              slug
            }
          }
          campaignTag {
            nodes {
              slug
            }
          }
        }
      }
    }
    allNews: allWpNews(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          slug
          title
          excerpt
          content
          date(formatString: "MMMM DD, YYYY")
          modified(formatString: "MMMM DD, YYYY")
          seo {
            metaDesc
          }
          newsTag {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
        next {
          slug
        }
        previous {
          slug
        }
      }
    }
  }
  `
  )
  
  // 言語・テキスト設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;
  
  
  // 言語別URL先頭設定
  let langUrl = (setLang === 'en') ? '/' :  '/' + setLang + '/';

  // データ取得
  // const postsArray = {
  //   ja: data.jaBlog.edges,
  //   en: data.enBlog.edges,
  //   sub: data.blogCategory.edges
  // }

  // console.log(postsArray.sub)

  // キャンペーン詳細ページ生成------------------------------------------------------
  let postArray = data.allNews.edges;
  // console.log(postArray)

  const setLangArray = [{lang:"ja"},{lang:"en"}]; // 言語配列作成
  const postType = 'news';

  setLangArray.map((result1) => {
    let langSetArray = postArray.filter(item => item.node.newsTag.nodes[0].slug === result1.lang);
    
    langSetArray.map((result2, index) =>{
      const resultArray = {
        path: result1.lang === 'en'? `/${postType}/${result2.node.slug}/`: `/${result1.lang}/${postType}/${result2.node.slug}/`,
        context: {
          selectLang: result1.lang,
          selectType: postType,
          previousSlug: langSetArray[index - 1]?.node.slug,
          nextSlug: langSetArray[index + 1]?.node.slug,
          slug: result2.node.slug,
          seo: result2.node.seo,
          data: result2.node
        },
      }
      console.log(resultArray)
    })
  })

  // キャンペーン詳細ページ生成------------------------------------------------------
  // let postArray = data.campaignData.edges;
  // // console.log(postArray)

  // const setType = [
  //   {category:"depositcampaign",    lang:"ja", uri:"campaign"},
  //   {category:"depositcampaign",    lang:"en", uri:"campaign"},
  //   {category:"accountopeningbonus",lang:"ja", uri:"account-opening-bonus"},
  //   {category:"accountopeningbonus",lang:"en", uri:"account-opening-bonus"},
  //   {category:"tradecampaign",      lang:"ja", uri:"trade-campaign"},
  //   {category:"tradecampaign",      lang:"en", uri:"trade-campaign"}
  // ];

  // setType.map((result1) => {
  //   let setArray = postArray.filter(item => item.node.campaignCategory.nodes[0].slug === result1.category);
  //   setArray = setArray.filter(item => item.node.campaignTag.nodes[0].slug === result1.lang);
    
  //   setArray.map((result2, index) =>{
  //     const resultArray = {
  //       path: result1.lang === 'en'? `/${result1.uri}/${result2.node.slug}/`: `/${result1.lang}/${result1.uri}/${result2.node.slug}/`,
  //       context: {
  //         previousSlug: setArray[index - 1]?.node.slug,
  //         slug: result2.node.slug,
  //         nextSlug: setArray[index + 1]?.node.slug,
  //         lang: result1.lang,
  //         category: result1.category,
  //         data: result2
  //       },
  //     }
  //     // console.log(resultArray)
  //   })
  // })


  // カスタムページ生成------------------------------------------------------
  // let postArray = data.custompageData.edges;
  
  // const postType = 'custompage';
  // const customType = 'zerozerocampaign';
  // const langArray = ['en', 'ja']

  // postArray = postArray.filter(item => item.node.slug === customType)[0].node;

  // // console.log(postArray)

  // langArray.map((result) => {
  //   const resultArray = {
  //     path: result === 'en'? `/${customType}/`: `/${result}/${customType}/`,
  //     context: {
  //       selectLang: result,
  //       selectType: postType,
  //       selectCustomType: customType,
  //       data: postArray
  //     },
  //   }
  //   console.log(resultArray)
  // })

  

  // postsBlogArray.map((result1) => {

  //   let setCategoryArray = categoryArray; // 言語別データありカテゴリ判別
  //   setCategoryArray.map((data) => { // 元別カテゴリ表示用配列作成
  //     let check = result1.data.some(item => {
  //       return item.node.articleCategory.nodes.some(category => category.slug === data.slug)   
  //     });
  //     if (check === false) {
  //       setCategoryArray = setCategoryArray.filter(item => item.slug !== data.slug);
  //     }
  //   })
  //   setCategoryArray.unshift({jaName: '全ての記事', enName: 'All blogs', slug: 'all'}) // all追加

  //   result1.data.map((result2) => {
  //     const resultArray = {
  //       path: result1.lang === 'en'? `/${postType}/${result2.node.slug}/`: `/${result1.lang}/${postType}/${result2.node.slug}/`,
  //       context: {
  //         selectLang: result1.lang,
  //         selectType: postType,
  //         previousSlug: result2.previous?.slug,
  //         nextSlug: result2.next?.slug,
  //         slug: result2.node.slug,
  //         category: result2.node.articleCategory.nodes[0].slug,
  //         categoryArray: setCategoryArray,
  //         data: result2.node
  //       },
  //     }
  //     console.log(resultArray)
  //   })
  // })


  // ブログ詳細ページ生成------------------------------------------------------
  // const postsJaBlog = postsArray.ja;
  // const postsEnBlog = postsArray.en;
  // const postsBlogArray = [{data: postsJaBlog, lang: 'ja'}, {data: postsEnBlog, lang: 'en'}]

  // const postsBlogCategory = postsArray.sub;
  
  // let categoryArray = [] // カテゴリー配列作成
  // postsBlogCategory.map((result) => {
  //   categoryArray.push({jaName: result.node.name, enName: result.node.description, slug: result.node.slug})
  // })

  // const postType = 'blog';

  // postsBlogArray.map((result1) => {

  //   let setCategoryArray = categoryArray; // 言語別データありカテゴリ判別
  //   setCategoryArray.map((data) => { // 元別カテゴリ表示用配列作成
  //     let check = result1.data.some(item => {
  //       return item.node.articleCategory.nodes.some(category => category.slug === data.slug)   
  //     });
  //     if (check === false) {
  //       setCategoryArray = setCategoryArray.filter(item => item.slug !== data.slug);
  //     }
  //   })
  //   setCategoryArray.unshift({jaName: '全ての記事', enName: 'All blogs', slug: 'all'}) // all追加

  //   result1.data.map((result2) => {
  //     const resultArray = {
  //       path: result1.lang === 'en'? `/${postType}/${result2.node.slug}/`: `/${result1.lang}/${postType}/${result2.node.slug}/`,
  //       context: {
  //         selectLang: result1.lang,
  //         selectType: postType,
  //         previousSlug: result2.previous?.slug,
  //         nextSlug: result2.next?.slug,
  //         slug: result2.node.slug,
  //         category: result2.node.articleCategory.nodes[0].slug,
  //         categoryArray: setCategoryArray,
  //         data: result2.node
  //       },
  //     }
  //     console.log(resultArray)
  //   })
  // })

  // ブログアーカイブページ生成------------------------------------------------------
  // const chunk = require(`lodash/chunk`)
  // const num = 6; // 1ページに表示するデータ数を設定
  // const setLangArray = [{lang:"ja"},{lang:"en"}]; // 言語配列作成
  // let categoryArray = [] // カテゴリー配列作成
  // postsArray.sub.map((result) => {
  //   categoryArray.push({jaName: result.node.name, enName: result.node.description, slug: result.node.slug})
  // })

  // setLangArray.map((result1) => {
  //   let langSetArray = postsArray.Master.filter(item => item.node.articleTag.nodes[0].slug === result1.lang); // 言語判別    
  //   let setCategoryArray = categoryArray; // 言語別データありカテゴリ判別
  //   setCategoryArray.map((data) => {
  //     let check = postsArray.Master.some(item => {
  //       return item.node.articleCategory.nodes.some(category => category.slug === data.slug)
  //           && item.node.articleTag.nodes.some(tag => tag.slug === result1.lang);
  //     });
  //     if (check === false) {
  //       setCategoryArray = setCategoryArray.filter(item => item.slug !== data.slug);
  //     }
  //   })
  //   setCategoryArray.unshift({jaName: '全ての記事', enName: 'All blogs', slug: 'all'}) // all用追加
  //   console.log(setCategoryArray)
    
  //   setCategoryArray.map((result2) =>{
  //     let setArray;
  //     result2.slug === 'all'? setArray = langSetArray: setArray = langSetArray.filter(item => item.node.articleCategory.nodes[0].slug === result2.slug);

  //     if (setArray.length !== 0) {
  //       setArray = chunk(setArray, num);
  //       let maxNum = setArray.length;
  //       let uri = 'blog';

  //       setArray.map((result3, index) =>{
  //         let langUrl = result3[0].node.articleTag.nodes[0].slug === 'en'? '/' :  '/' + result3[0].node.articleTag.nodes[0].slug + '/';
  //         const resultArray = {
  //           path: result2.slug === 'all'? `${langUrl}${uri}/${index + 1}/`: `${langUrl}${uri}/${result2.slug}/${index + 1}/`,
  //           context: {
  //             num: index + 1,
  //             maxnum: maxNum,
  //             uri: uri,
  //             category: result2,
  //             categoryArray: setCategoryArray,
  //             lang: result3[0].node.articleTag.nodes[0].slug,
  //             data: result3
  //           },
  //         }
  //         // console.log(resultArray)
  //       })
  //     }
  //   })    
  // })

  // -------------------------------------------------------------------

  // let testarray = array.filter(item => item.node.campaignCategory.nodes[0].slug === setType[0].category);
  // testarray = testarray.filter(item => item.node.campaignTag.nodes[0].slug === setType[0].lang);
  // if (testarray.length !== 0) {
  //   testarray = chunk(testarray, num);
  // }
  // console.log(testarray)

  // let testarray2 = array.filter(item => item.node.campaignCategory.nodes[0].slug === setType[2].category);
  // testarray2 = testarray2.filter(item => item.node.campaignTag.nodes[0].slug === setType[2].lang);
  // console.log(testarray2)
  

  // キャンペーンアーカイブページ生成
  // const chunk = require(`lodash/chunk`)
  // const num = 6; // 1ページに表示するデータ数を設定

  // const setType = [
  //   {category:"depositcampaign",    lang:"ja", uri:"campaign"},
  //   {category:"depositcampaign",    lang:"en", uri:"campaign"},
  //   {category:"accountopeningbonus",lang:"ja", uri:"account-opening-bonus"},
  //   {category:"accountopeningbonus",lang:"en", uri:"account-opening-bonus"},
  //   {category:"tradecampaign",      lang:"ja", uri:"trade-campaign"},
  //   {category:"tradecampaign",      lang:"en", uri:"trade-campaign"}
  // ];

  // const camArchiveArray = postsArray.Master;

  // setType.map((result) => {
  //   let setArray = camArchiveArray.filter(item => item.node.campaignCategory.nodes[0].slug === result.category);
  //   setArray = setArray.filter(item => item.node.campaignTag.nodes[0].slug === result.lang);
  //   if (setArray.length !== 0) {
  //     setArray = chunk(setArray, num);
  //   }
  //   // console.log(setArray)
  //   let maxNum = setArray.length;
  //   let uri = result.uri;
    
  //   setArray.map((result, index) =>{
  //     let langUrl = result[0].node.campaignTag.nodes[0].slug === 'en'? '/' :  '/' + result[0].node.campaignTag.nodes[0].slug + '/';
  //     const resultArray = {
  //       path: `${langUrl}${uri}/${index + 1}/`,
  //       context: {
  //         num: index + 1,
  //         maxnum: maxNum,
  //         uri: uri,
  //         lang: result[0].node.campaignTag.nodes[0].slug,
  //         category: result[0].node.campaignCategory.nodes[0].slug,
  //         data: result
  //       },
  //     }
  //     // console.log(resultArray)
  //   })
  // })



  // const totalPages = postsStoreChunk.length
  // console.log(postsStore)
  // console.log(postsStoreChunk)
  // console.log(totalPages)

  

  // console.log(setArray)

  

  // return Promise.all(
  //   postLang.map(async (lang) => {
  //     Object.keys(mediaArchiveArray).map(async (result1) => {
  //       mediaArchiveArray[result1].map(async (result2, index) => {
  //         let pageUri;
  //         (index !== 0 ? pageUri = index + 1 + '/': pageUri = '')
  //         await gatsbyUtilities.actions.createPage({
  //           path: (lang === 'ja' ? 'media/' + pageUri: lang + '/media/' + pageUri),
  //           component: path.resolve(`./src/templates/media-archive.js`),
  //           context: {
  //             Page: index + 1,
  //             AllPage: mediaArchiveArray[result1].length,
  //             Lang: lang,
  //             Data: result2,
  //             AllData: mediaArchiveArray[result1]
  //           },
  //         })
  //       })
  //     })
  //   })
  // )  


  // selectLang, selectType, nextPagePath, previousPagePath


  // termsArray.map((result1) => {
  //   // termsArray[result].node.smartCustomFields.map((result2) => {
      
  //   result1.node.smartCustomFields.map((result2) => {
  //     const setArray = {
  //       path: (result1.node.termSettingTag.nodes[0].slug === 'en' ? 
  //                 `/${result1.node.slug}/${result2.termslug}/`: 
  //                 `/${result1.node.termSettingTag.nodes[0].slug}/${result1.node.slug}/${result2.termslug}/`),
  //       context: {
  //         postSlug: result2.termslug,
  //         parentSlug: result1.node.slug,
  //         lang: result1.node.termSettingTag.nodes[0].slug,
  //         data: result2
  //       },
  //     }
  //     console.log(setArray)
  //   })
    
  // })

  
  
  // 田園プラザの楽しみ方　アーカイブ-------------
  // let postsStoreCategory = poststArray.StoreCategory;
  // let postsStoreTag = poststArray.StoreTag;
  // // 指定のタグを削除
  // const valuesToRemove = ['pickup']; // 削除タグを増やす場合配列に追加
  // postsStoreTag = postsStoreTag.filter(item => !valuesToRemove.includes(item.node.slug));
  // postsStoreCategory.map((result1) => {
  //   postsStoreTag.map((result2) => {
      
  //     const setArray = {
  //       path: (result2.node.slug === 'ja' ? result1.node.slug: result2.node.slug + '/' + result1.node.slug),
  //       context: {
  //         page: result1.node.slug,
  //         lang: result2.node.slug
  //       },
  //     }
  //     console.log(setArray)
  //   })
  // })
  // console.log(postsStoreCategory)


  // 田園プラザの楽しみ方 詳細ページ---------------------------------------------------------
  // console.log(postsArray)

  // let resultArray = postsArray.Master
  // // 多言語削除処理
  // resultArray = resultArray.filter(function(item) {
  //   let slug = item.post.storeTag.nodes[0].slug;
  //   return slug !== "en" && slug !== "ch" && slug !== "ko";
  // });


  // for (let i = 0; i < resultArray.length; i++) {
  //   let item = resultArray[i];
  //   if (item.post.storeTag.nodes[0].slug === "en" && item.post.slug.endsWith("-en")) {
  //     item.post.slug = item.post.slug.slice(0, -3); // 末尾の "-en" を削除
  //   } else if (item.post.storeTag.nodes[0].slug === "ch" && item.post.slug.endsWith("-ch")) {
  //     item.post.slug = item.post.slug.slice(0, -3); // 末尾の "-ch" を削除
  //   } else if (item.post.storeTag.nodes[0].slug === "ko" && item.post.slug.endsWith("-ko")) {
  //     item.post.slug = item.post.slug.slice(0, -3); // 末尾の "-ko" を削除
  //   }
  // }



  // resultArray.map((result) => {
  //   const setArray = {
  //     path: (result.post.storeTag.nodes[0].slug === 'ja' ? 
  //              result.post.storeCategory.nodes[0].slug + '/' + result.post.slug: 
  //              result.post.storeTag.nodes[0].slug + '/' + result.post.storeCategory.nodes[0].slug + '/' + result.post.slug),
  //     context: {
  //       previousSlug: result.previous ? result.previous.slug : '',
  //       postSlug: result.post.slug,
  //       nextSlug: result.next ? result.next.slug : '',
  //       category: result.post.storeCategory.nodes[0].slug,
  //       tag: result.post.storeTag.nodes[0].slug,
  //       data: result.post
  //     },
  //   }
  //   console.log(setArray)
  // })


  // 固定ページ他言語化---------------------------------------------------------
  // let postsFixed = postsArray.Master;
  // let fixedPages = [];
  // const languageMapping = {
  //   "英語": "en",
  //   "中国語": "ch",
  //   "韓国語": "ko"
  // };
  // postsFixed.forEach((item) => {
  //   const generatingpages = item.node.postLangSetting.generatingpages;
  //   const slug = item.node.slug;
  //   if (generatingpages && generatingpages.length > 1) {
  //     generatingpages.forEach((lang) => {
  //       if (lang !== "日本語" && languageMapping[lang]) {
  //         fixedPages.push({ slug, generatingpages: languageMapping[lang] });
  //       }
  //     });
  //   }
  // });
  // fixedPages.map((result) => {
  //   const setArray = {
  //     path: result.generatingpages + '/' + result.slug + '/' ,
  //     context: {
  //       langType: result.generatingpages,
  //       slug: result.slug
  //     },
  //   }
  //   console.log(setArray)
  // })

  // return (
  const pageData = <>
    <Layout>

      <div className="" langType={setLang}>
        {/* <p className="text-[#FE0000]">page name:{pageTitle}</p> */}
        <p>set lang:{setLang}</p>
        {/* <IframeResizer
          id="widgetFrame"
          style={{ minWidth: '100%' }}
          src="https://ratingsadmin.miltonmarkets.com/widgets/ratings?widgetKey=Milton&theme=light&lang=ja"
        /> */}
      </div>

    </Layout>
  </>
  // )
  return BasicAuthentication(pageData)
}
export default Test